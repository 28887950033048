<script setup>
import Layout from '../Layouts/SplitForm.vue'
import { Head } from '@inertiajs/vue3'
import {usePage} from "@inertiajs/vue3";
const page = usePage();
const user = page.props.user;

</script>

<template>
    <Head>
        <title>Choose Plan Tier RECEIPT - Yoclo</title>
        <meta name="description" content="">
    </Head>

    <Layout>
        <h1>Choose Plan Tier RECEIPT</h1>

    </Layout>
</template>
