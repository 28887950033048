
<script setup>
import Layout from '@/Layouts/Admin.vue'
import { Head } from '@inertiajs/vue3'
import { computed, onMounted } from 'vue';
import TopBar from "@/Components/Admin/TopBar.vue";
import Pagination from "@/Components/Admin/Pagination.vue";

const props = defineProps({
    users: Object
});

onMounted(() => {

});

function deleteUser(user) {
    if (confirm('Are you sure you want to delete this user?')) {
        // Delete the user
    }
}

</script>

<template>
    <Head>
        <title>Manage Users - Yoclo</title>
    </Head>

    <Layout>
        <top-bar title="Manage Users"></top-bar>

        <table class="min-w-full divide-y divide-gray-300">
            <thead>
                <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Account type</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Username</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Registration date</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
                <tr v-for="user in users.data">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 capitalize">{{ user.account_type }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.name }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.email }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ new Date(user.created_at).toLocaleDateString() }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span v-if="user.status === 'active'" class="capitalize inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            {{ user.status }}
                        </span>
                        <span v-else-if="user.status  === 'pending'" class="capitalize inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                            {{ user.status }}
                        </span>
                        <span v-else class="capitalize inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Inactive</span>

                    </td>
                    <td>
                        <a :href="'/admin/users/edit/' + user.id" type="button" class="mr-2 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">View</a>
                        <button @click="deleteUser(user)" type="button" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <pagination :data="users"></pagination>
    </Layout>
</template>
