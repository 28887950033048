<script setup>
import {useForm, usePage} from '@inertiajs/vue3'
import InputText from "@/Components/Forms/InputText.vue";
import InputLabel from "@/Components/Forms/InputLabel.vue";
import InputTextArea from "@/Components/Forms/InputTextArea.vue";
import InputButton from "@/Components/Forms/InputButton.vue";
import ConnectedAccounts from "@/Components/Yoclo/ConnectedAccounts.vue";
import ImageUploadWithCrop from "@/Components/ImageUploadWithCrop.vue";
import {Inertia} from "@inertiajs/inertia";
import Multiselect from 'vue-multiselect'
import { marketTagOptions } from "@/data/marketTagOptions.js";

const page = usePage();
const user = page.props.auth.user;

const form = useForm({
    biography: user.biography || null,
    // this field could be changed to brand_name
    name: user.name || null,
    email: user.email || null,
    contact_email: user.contact_email || null,
    password: null,  // Leave password empty for user to fill in manually
    remember: false,
    market_tags: user.market_tags || null,
    // This can be changed to profile picture or brand logo -->
    profile_picture: user.profile_picture || null,
    cover_photo: user.cover_photo || null // Assuming user may upload a profile picture
});


const updateDetails = () => {
    form.post('/user/update', {
        onSuccess: () => {
            // Refresh the page data after the form submission is successful
            Inertia.reload({
                only: ['user'],  // Replace with the specific data you want to reload, or remove 'only' to reload everything
                preserveScroll: true, // Preserve scroll position
                preserveState: false,  // Reset the form state
            });
        }
    });
};

</script>

<template>
    <div class="flex justify-between">
        <h5 class="font-regular text-4xl text-gray-800 dark:text-white leading-tight tracking-tighter">
            Update brand profile
        </h5>
    </div>
    <div class="relative overflow-y-auto max-h-full">
        <form class="form-content" @submit.prevent="updateDetails">
            <div class="grid gap-6 mb-14">
                <div class="max-w-md">
                    <input-label for="name" value="Brand name"/>
                    <input-text
                        v-model="form.name"
                        id="name"
                        name="name"
                        type="text"
                        class="mt-1 block w-full"
                        autocomplete="name"
                    />
                    <div v-if="form.errors.name" class="text-red-500 text-sm mt-1">
                        {{ form.errors.name }}
                    </div>
                </div>

                <div class="max-w-md">
                    <h4>Brand logo</h4>
                    <image-upload-with-crop
                        :default-image="form.brand_logo"
                        aspect-ratio="1"
                        :crop-box-resizable="true"
                        :crop-box-width="200"
                        :crop-box-height="200"
                        crop-classes="rounded-full"
                        border-radius="50%"
                        v-model="form.brand_logo"/>

                    <div v-if="form.errors.brand_logo" class="text-red-500 text-sm mt-1">
                        {{ form.errors.brand_logo }}
                    </div>
                </div>

                <div class="max-w-md">
                    <h4>Brand image</h4>
                    <image-upload-with-crop
                        aspect-ratio="5/2"
                        :crop-box-resizable="true"
                        crop-box-width="100%"
                        crop-box-height="200"
                        border-radius="0"
                        :default-image="form.cover_photo"
                        v-model="form.cover_photo"/>

                    <div v-if="form.errors.cover_photo" class="text-red-500 text-sm mt-1">
                        {{ form.errors.cover_photo }}
                    </div>
                </div>

                <div>
                    <input-label for="biography" value="Biography"/>
                    <input-text-area
                        v-model="form.biography"
                        rows="5"
                        id="biography"
                        name="biography"
                        type="text"
                        class="mt-1 block w-full"
                        autocomplete="biography"
                    />

                    <div v-if="form.errors.biography" class="text-red-500 text-sm mt-1">
                        {{ form.errors.biography }}
                    </div>
                </div>

                <div class="max-w-md">
                    <input-label for="tags" value="Add market tags (maximum 3)" class="mb-2" />
                    <multiselect
                        :show-labels="false"
                        :close-on-select="false"
                        :multiple="true"
                        :options="marketTagOptions"
                        track-by="id"
                        label="name"
                        :max="3"
                        v-model="form.market_tags"
                    />
                </div>
            </div>

            <div class="lg:col-span-2 prose text-white mb-6">
                <h6 class="text-4xl font-regular">Link social accounts</h6>
                <p>
                    Enhance Your Profile - Connect Your Social Media:
                </p>

                <ul>
                    <li>Automatic Updates: Keep your stats and engagement data fresh with no extra effort.</li>
                    <li>Boost Credibility: Show verified, real-time insights to brands.</li>
                    <li>Secure & Private: We never store your login details and only access public profile
                        information.
                    </li>
                </ul>
                <p>Connect in a click, and stay in control – you can disconnect anytime.</p>
            </div>

            <connected-accounts></connected-accounts>

            <div class="lg:col-span-2 space-y-12p-4 mt-4">
                <div class="flex justify-end">
                    <button class="text-white me-6 underline">I'll connect my social accounts later</button>
                    <input-button type="submit" :disabled="form.processing">
                        {{ form.processing ? 'Saving...' : 'Save' }}
                    </input-button>
                </div>
            </div>
        </form>
    </div>
</template>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
