<script setup>
import { ref, watch, onMounted, onUnmounted, nextTick, computed } from 'vue';
import SvgIcon from "@/Components/SvgIcon.vue";

// Props
const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    },
    maxWidth: {
        type: String,
        default: '2xl'  // Default is '2xl', can be 'sm', 'md', 'lg', 'xl', '2xl', 'full'
    },
    focusable: {
        type: Boolean,
        default: true // Whether to focus on the first focusable element
    }
});

// Emits
const emit = defineEmits(['update:modelValue']);

// Reactive variable to control modal visibility
const isOpen = ref(false);

// Focus management variables
const modalRef = ref(null);
const focusables = ref([]);

// Watcher to synchronize prop and internal state
watch(
    () => props.modelValue,
    async (newValue) => {
        isOpen.value = newValue;
        if (newValue) {
            document.body.classList.add('overflow-y-hidden');
            await nextTick();
            if (props.focusable && modalRef.value) {
                focusables.value = getFocusables(modalRef.value);
                focusables.value[0]?.focus();
            }
        } else {
            document.body.classList.remove('overflow-y-hidden');
        }
    }
);

// Get all focusable elements inside the modal
const getFocusables = (element) => {
    const selector = 'a, button, input:not([type="hidden"]), textarea, select, details, [tabindex]:not([tabindex="-1"])';
    return [...element.querySelectorAll(selector)].filter(el => !el.hasAttribute('disabled'));
};

// Function to close the modal
const close = () => {
    isOpen.value = false;
    emit('update:modelValue', false);
};

// Event listener for escape key to close modal
const handleEscape = (e) => {
    if (e.key === 'Escape') {
        close();
    }
};

// Lifecycle hooks
onMounted(() => {
    document.addEventListener('keydown', handleEscape);
});

onUnmounted(() => {
    document.removeEventListener('keydown', handleEscape);
});

// Mapping maxWidth prop to Tailwind classes
const maxWidthClass = computed(() => {
    return {
        'sm:max-w-sm': props.maxWidth === 'sm',
        'sm:max-w-md': props.maxWidth === 'md',
        'sm:max-w-lg': props.maxWidth === 'lg',
        'sm:max-w-xl': props.maxWidth === 'xl',
        'sm:max-w-5xl': props.maxWidth === '2xl',
        'sm:max-w-full': props.maxWidth === 'full', // Full screen width option
    };
});
</script>

<template>
    <div v-if="isOpen" class="fixed inset-0 z-50 flex items-center justify-center">
        <!-- Modal background -->
        <div
            class="fixed inset-0 bg-black bg-opacity-50"
            @click="close"
        />

        <!-- Modal content -->
        <div
            ref="modalRef"
            class="relative bg-white dark:bg-[#181A1B] rounded-lg shadow-lg py-24 ps-20 z-10 sm:w-full h-full"
            :class="[maxWidthClass, { 'transition-opacity duration-300 ease-out': isOpen, 'opacity-100': isOpen }]"
            style="max-height: calc(100vh - 100px)"
        >
            <!-- Modal close button -->
            <button
                @click="close"
                class="absolute top-5 right-5 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
            >
                <svg-icon name="close" />
            </button>

            <!-- Modal slot for dynamic content -->
            <slot></slot>

            <!-- Modal footer slot (optional) -->
            <div v-if="$slots.footer" class="mt-4">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>
y
