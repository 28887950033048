<script setup>
import Layout from '@/Layouts/Authed.vue'
import { Head, usePage } from '@inertiajs/vue3'
import SocialCounts from "@/Components/Yoclo/SocialCounts.vue";
import Badge from '@/Components/Layout/Badge.vue'
import InputButton from "@/Components/Forms/InputButton.vue";
import InputTab from "@/Components/Forms/InputTab.vue";
import UserProfileSettings from "@/Components/Yoclo/UserProfileSettings.vue";
import Modal from "@/Components/Layout/Modal.vue";
import { marketTagOptions } from "@/data/marketTagOptions.js";
import {ref} from "vue";
import SocialIcon from "@/Components/Yoclo/SocialIcon.vue";
import {getMarketTagIcon} from "@/utils/helpers.js";

const page = usePage();
const user = page.props.auth.user;

const isModalOpen = ref(false);

// Function to toggle modal visibility

const showModal = () => {
    isModalOpen.value = true;
}

const closeModal = () => {
    isModalOpen.value = false;
};

// Set up reactive state for activeTab
const activeTab = ref('overview')

// Method to set active tab
const setActiveTab = (tab) => {
    activeTab.value = tab
}

</script>


<template>
    <Head>
        <title>Profile - Yoclo</title>
    </Head>

    <Layout>
        <div
            v-if="user?.is_bespoke_image"
            class="absolute bg-cover bg-center bg-no-repeat h-full w-full md:w-[80%] inset-0 md:left-[20%] z-0 max-h-[60vh]"
            >
        </div>
        <div v-if="!user?.is_bespoke_image" class="relative">
            <div class="bg-cover bg-center bg-no-repeat w-full h-[526px] inset-0 z-0 max-h-[60vh]"
                 :style="{ 'background-image': `url(${user?.cover_photo})`}">

            <div class="absolute top-0 bottom-0 left-0 right-0 z-10" style="background-image: linear-gradient(to right, black 30%, rgba(43, 47, 50, 0) 100%)"></div>

                <div class="relative z-20 pt-10 text-white">
                    <div class="container text-right">
                        <input-button @click="showModal">Update profile page</input-button>
                    </div>

                    <div class="container">
                        <Badge icon="pin" v-if="user?.location">{{ user?.location }}</Badge>
                        <div class="spacer inline-block mx-3" v-if="user?.location">|</div>
                        <Badge v-for="(tag, index) in user?.market_tags" :class="index !== 0 && 'ms-2'">
                        <component v-if="marketTagOptions[index].icon" :is="getMarketTagIcon(tag.name)" />
                            {{ tag.name }}
                        </Badge>

                        <h1 class="text-7xl font-medium mt-6" v-if="!user?.professional_alias || !user?.show_alias">{{ user?.first_name }} {{ user?.last_name}}</h1>
                        <h1 class="text-7xl font-medium mt-6" v-if="user?.professional_alias && user?.show_alias">{{ user?.professional_alias }}</h1>
                        <p class="text-2.5xl mb-4">12 Total following</p>
                        <social-counts></social-counts>
                    </div>
                </div>
            </div>
        </div>

        <div class="container pb-20">
            <!-- Tabs Navigation -->
            <nav class="-mb-px flex space-x-8 mb-12" aria-label="Tabs">
                <input-tab
                    :active="activeTab === 'overview'"
                    @click="setActiveTab('overview')"
                    label="Overview"
                />
                <input-tab
                    :active="activeTab === 'latestPosts'"
                    @click="setActiveTab('latestPosts')"
                    label="Latest Posts"
                />
                <input-tab
                    :active="activeTab === 'about'"
                    @click="setActiveTab('about')"
                    label="About"
                />
            </nav>

            <!-- Content for Overview Tab -->
            <div v-if="activeTab === 'overview'">
               <div class="mb-20 grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-12">
                   <div v-for="i in 3" :key="i">
                       <social-icon platform="tiktok" />
                       <span class="inline-block ms-2 mb-2">5 hours ago</span>
                       <div class="shadow-lg rounded-3xl h-[488px] bg-[#171B1F]"></div>
                       <ul class="flex justify-between font-light mt-5 text-white">
                           <li>
                               5000 <strong class="font-medium">Likes</strong>
                           </li>
                           <li>
                               400000 <strong class="font-medium">Comments</strong>
                           </li>
                           <li>
                               100000 <strong class="font-medium">Views</strong>
                           </li>
                       </ul>
                   </div>
               </div>
            </div>

            <!-- Content for Latest Posts Tab -->
            <div v-if="activeTab === 'latestPosts'" class="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-12">
                <div v-for="i in 6" :key="i">
                    <social-icon platform="tiktok" />
                    <span class="inline-block ms-2 mb-2">5 hours ago</span>
                    <div class="shadow-lg rounded-3xl h-[488px] bg-[#171B1F]"></div>
                    <ul class="flex justify-between font-light mt-5 text-white">
                        <li>
                            5000 <strong class="font-medium">Likes</strong>
                        </li>
                        <li>
                            400000 <strong class="font-medium">Comments</strong>
                        </li>
                        <li>
                            100000 <strong class="font-medium">Views</strong>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Content for About Tab -->
            <div v-if="activeTab === 'about'" class="max-w-2xl">
                <strong class="mb-6 block">About me</strong>
                <p>
                    {{ user?.biography }}
                </p>
            </div>
        </div>

        <modal v-model="isModalOpen">
            <user-profile-settings />
        </modal>
    </Layout>
</template>
