<script setup>
import Layout from '@/Layouts/Authed.vue'
import {usePage} from "@inertiajs/vue3";
import SearchContainer from "@/Components/Layout/SearchContainer.vue";

const page = usePage();
const user = page.props.auth.user;
</script>

<template>
    <Layout>
        <SearchContainer :type="'creator'" :sub-title="'Discover the perfect creator for your brand campaign'"></SearchContainer>
    </Layout>
</template>
